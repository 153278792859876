<template>
  <Modal v-model="proxyValue" size="lg" id="feedbackModal">
    <form class="d-flex flex-column gap-3">
      <RadioGroup
        v-model="form.satisfaction_with_usability"
        name="satisfaction_with_usability"
        :options="zufriedenheitOptions"
        :label="true"
        :inline="true"></RadioGroup>
      <RadioGroup
        v-model="form.satisfaction_with_jobs"
        name="satisfaction_with_jobs"
        :options="zufriedenheitOptions"
        :label="true"
        :inline="true"></RadioGroup>
      <RadioGroup
        v-if="application.redirect_url"
        v-model="form.satisfaction_with_bms_process"
        name="satisfaction_with_bms_process"
        :options="zufriedenheitOptions"
        :label="true"
        :inline="true"></RadioGroup>
      <RadioGroup
        v-model="form.satisfaction_with_application"
        name="satisfaction_with_application"
        :options="zufriedenheitOptions"
        :label="true"
        :inline="true"></RadioGroup>
      <!-- nur initiaitv -->
      <RadioGroup
        v-if="false"
        v-model="form.satisfaction_with_presentation"
        name="satisfaction_with_presentation"
        :options="zufriedenheitOptions"
        :label="true"
        :inline="true"></RadioGroup>

      <RadioGroup name="recommend" v-model="form.recommend" :options="yesNoOptions" :inline="true" :label="true"></RadioGroup>

      <RadioGroup name="would_use_again" v-model="form.would_use_again" :options="yesNoOptions" :label="true" :inline="true"></RadioGroup>

      <FormInput
        v-model="form.comment"
        name="comment"
        type="textarea"
        :label="$t('js.form.properties.feedback.comment')"
        placeholder=""
        rows="2"></FormInput>

      <Button
        v-show="!isValid"
        variant="white"
        type="submit"
        class="my-2 opacity-50"
        size="lg"
        @click.prevent="v$.$touch()"
        name="invalidButton"
        v-tooltip="tooltip">
        {{ $t("js.components.apply.feedback_modal.submit_button") }}
      </Button>
      <Button v-show="isValid" @click.prevent="submit" variant="primary" class="my-2" size="lg" :disabled="isSubmitting">
        {{ $t("js.components.apply.feedback_modal.submit_button") }}
      </Button>
    </form>
  </Modal>
</template>

<script lang="ts" setup>
import { ref, computed, provide } from "vue"
import { Modal, Button, RadioGroup, FormInput } from "@/elements"
import { useI18n } from "vue-i18n"
import useVuelidate from "@vuelidate/core"
import { required } from "@/utils/validators"
import * as Routes from "~/routes"

const props = defineProps<{
  modelValue: boolean
  application: SucceedApplication
}>()

const form = ref({
  satisfaction_with_usability: 0,
  satisfaction_with_application: 0,
  satisfaction_with_presentation: 0,
  satisfaction_with_bms_process: 0,
  satisfaction_with_jobs: 0,
  recommend: null,
  would_use_again: null,
  comment: "",
})
const v$ = useVuelidate(
  {
    satisfaction_with_usability: { required },
    satisfaction_with_application: { required },
    //satisfaction_with_presentation: { required },
    satisfaction_with_bms_process: { required },
    satisfaction_with_jobs: { required },
    recommend: { required },
    would_use_again: { required },
  },
  form,
  {}
)
provide("v", v$)

const $t = useI18n().t

const emit = defineEmits(["update:modelValue", "answer"])

const proxyValue = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
})

const zufriedenheitOptions = computed(() => {
  return [
    { value: 0, text: $t("js.components.apply.feedback_modal.zufriedenheit_options.no_statement") },
    { value: 1, text: $t("js.components.apply.feedback_modal.zufriedenheit_options.unsatisfied") },
    { value: 2, text: $t("js.components.apply.feedback_modal.zufriedenheit_options.rather_unsatisfied") },
    { value: 3, text: $t("js.components.apply.feedback_modal.zufriedenheit_options.rather_satisfied") },
    { value: 4, text: $t("js.components.apply.feedback_modal.zufriedenheit_options.satisfied") },
  ]
})
const yesNoOptions = computed(() => {
  return [
    { value: true, text: $t("js.components.apply.feedback_modal.yes_no.yes_option") },
    { value: false, text: $t("js.components.apply.feedback_modal.yes_no.no_option") },
  ]
})

const tooltip = computed(() => {
  return {
    content: `
      <div>
      ${$t("js.components.apply_card.error_tooltip")}
      <ul class="mt-3 list-disc list-outside ml-5">
        ${v$.value.$silentErrors
          .map((e) => e.$message)
          .map((e) => `<li>${e}</li>`)
          .join("")}
      </ul>
      </div>
    `,
    class: "rounded shadow-lg px-4 py-2 bg-warning alert alert-warning",
    tippy: {
      trigger: "hover",
    },
  }
})
const isSubmitting = ref(false)
const isValid = computed(() => {
  return !v$.value.$invalid
})
const submit = async () => {
  await v$.value.$validate()
  if (v$.value.$invalid) {
    return
  }
  isSubmitting.value = true
  const body = {
    feedback: form.value,
    application: props.application,
  }
  fetch(Routes.jobApplicationsFeedbackPath(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.ok) {
        proxyValue.value = false
        isSubmitting.value = false
        emit("answer", true)
      } else {
        isSubmitting.value = false
      }
    })
    .catch((error) => {
      isSubmitting.value = false
      console.error(error)
    })
}
</script>

<style scoped></style>
