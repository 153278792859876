<template lang="pug">
.row
  .col-lg-3.d-none.d-lg-block
  .col-lg-9.d-none.d-lg-block: .mb-3
    .d-flex.flex-wrap.align-items-stretch.w-100
      .even-width.text-center(v-for="level in levels")
        small.fst-italic
          | {{ $t(`js.components.talent_profile.experience_levels.explanations.${level.value}`) }}

  template(v-for="(competence, idx) in proxyValue" :key="idx")
    .col-12.col-lg-3: .mb-3
      p(style="font-size: 1.2rem")
        | {{ competence[`name_${locale}`] }}
    .col-12.col-lg-9: .mb-3
      .d-flex.justify-mobile.flex-wrap.align-items-stretch.w-100
        template(v-for="level in levels")
          small.fst-italic.d-lg-none
            | {{ $t(`js.components.talent_profile.experience_levels.explanations.${level.value}`) }}
          button.btn.btn-primary.even-width.text-center.full-width-on-sm(
            :id="`competence-level-${idx}-${level.value}`"
            @click="addExp(idx, level.value)"
            :class="{ active: active(idx, level.value) }"
            style="text-transform: none;"
          )
            | {{ level.text }}


</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { computed } from "vue"
import { getOptionValues } from "../talents_form"

const levels = getOptionValues("experience_levels")

const { locale } = useI18n()

const props = defineProps<{
  modelValue: CompetenceInput[]
}>()

const emits = defineEmits(["update:modelValue"])

const proxyValue = computed({
  get() {
    return props.modelValue
  },
  set(value: CompetenceInput[]) {
    emits("update:modelValue", value)
  },
})

function addExp(idx: number, level: string) {
  const competence = proxyValue.value[idx]

  competence.experience_level = level
}

const active = (idx: number, level: string) => {
  const competence = proxyValue.value[idx]

  return competence.experience_level === level
}
</script>

<style scoped>
.btn-primary {
  padding: 5px;
  color: #fff;
  border-right: 1px solid #606060;
  border-radius: 0;
}
.even-width {
  width: calc(100% / 5);
}
.btn-primary:nth-child(2) {
  border-radius: 5px 0 0 5px;
}
.btn-primary:last-child {
  border-radius: 0 5px 5px 0;
}

.active {
  background: #bf436f !important;
}
@media (max-width: 992px) {
  .even-width {
    width: 100%;
    border-radius: 5px !important;
    margin-bottom: 5px;
  }
}

</style>
