import { useStorage } from '@vueuse/core'
import { computed } from 'vue'

type ApplicationResult = {
  jobApplications: Record<string, StoredApplicationV2>,
  unsolicitedApplications: Record<string, StoredApplicationV2>,
  feedbackAnswered: boolean
  feedbackGood: boolean
}

const formV2 = useStorage("previousApplications2", {
  jobApplications: {},
  unsolicitedApplications: {},
  feedbackAnswered: false,
  feedbackGood: false,
} as ApplicationResult)


export default formV2

const kanaleoAnswered = (application: SucceedApplication) => {
  const same = Object.values(formV2.value.jobApplications).find((app) => app.applied_on === application.applied_on)
  if (same) {
    same.kanaleo_answered = true
  }
}

const feedbackAnswered = (isGood: boolean = false) => {
  formV2.value.feedbackAnswered = true
  if (isGood) {
    formV2.value.feedbackGood = true
  }
}


const previousApplicationTo = (jobId: string) => {
  return formV2.value.jobApplications[jobId]
}

const addApplicationToHistory = (jobId: number, application: SucceedApplication, codeUsed: boolean) => {
  let val = formV2.value.jobApplications
  val[jobId] = {
    ...application,
    applied_on: (new Date()).toISOString(),
    kanaleo_answered: false,
    job_id: jobId.toString(),
    code_used: codeUsed,
    version: 2
  }
  formV2.value.jobApplications = val
}

const applicationCount = computed(() => {
  return Object.keys(formV2.value.jobApplications).length
})

export {
  formV2 as state,
  kanaleoAnswered,
  previousApplicationTo,
  applicationCount,
  addApplicationToHistory,
  feedbackAnswered
}
