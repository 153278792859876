<template>
  <ChecklistItem :done="feedbackDone" v-if="!feedbackDone">
    <div class="flex-grow-1">
      <div>
        {{ $t("js.components.apply_card.feedback") }}
      </div>
    </div>
    <div>
      <span v-for='i in 5'>
        <a href='#' @click.prevent='rate(i)' @mouseover='previewRating(i + 1)'>
          <svg viewBox='0 0 24 24' fill='currentColor' style='width: 24px; height: 24px'>
            <path :d='currentPreviewRating > i ? mdiStar : mdiStarOutline' />
          </svg>
        </a>
      </span>
    </div>
  </ChecklistItem>
  <ChecklistItem :done="feedbackDone" v-if="feedbackDone">
    <div class="flex-grow-1">
      <div>
        {{ $t("js.components.apply_card.feedback_success") }}
      </div>
    </div>
    <div v-if='showJobboersencheck'>
      <p class='fs-6'>Vielen Dank für Ihr wertvolles Feedback</p>
      <p>Wir freuen uns sehr, wenn Sie Ihre Erfahrung auch auf der unabhängigen Bewertungsplattform Jobbörsencheck teilen!
        Damit helfen Sie Arbeitgebern eine fundierte Meinung über Empfehlungsbund und seine Arbeitgebercommunitys
        einzuholen. Vielen Dank für Ihre Unterstützung!</p>

      <div class='text-center d-flex flex-column gap-2'>
        <a href="https://jobboersencheck.de/fragebogen/bewerber/basisdaten?jm=159" target="_blank"
          class="btn btn-primary btn-lg">Zu Jobbörsencheck</a>
      </div>
    </div>
  </ChecklistItem>
  <FeedbackModal v-model="feedbackModalOpen" :application="application" @answer="onFeedbackAnswered" />
</template>

<script lang="ts" setup>
import { ref, computed } from "vue"
import { Button } from "@/elements"
import ChecklistItem from "@/elements/ChecklistItem.vue"
import FeedbackModal from "./FeedbackModal.vue"
import { feedbackAnswered, state } from "@/components/apply/history"

import { mdiStarOutline, mdiStar } from "@mdi/js"

const props = defineProps<{
  application: StoredApplicationV2
}>()
const feedbackModalOpen = ref(false)
const feedbackDone = computed(() => {
  return state.value.feedbackAnswered
})
const showJobboersencheck = computed(() => {
  return state.value.feedbackGood && window.Locale == 'de'
})
const onFeedbackAnswered = () => {
  feedbackAnswered()
  feedbackModalOpen.value = false
}

const createStaticFeedback = (stars: number) => {
  const body = {
    feedback: {
      satisfaction_with_usability: stars,
      satisfaction_with_application: stars,
      satisfaction_with_presentation: stars,
      satisfaction_with_bms_process: stars,
      satisfaction_with_jobs: stars,
      recommend: true,
      would_use_again: true,
      comment: "",
    },
    application: props.application
  }
  fetch(Routes.jobApplicationsFeedbackPath(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
  feedbackAnswered(true)
}

import * as Routes from "~/routes"
const rate = (stars: number) => {
  if (stars >= 4) {
    createStaticFeedback(stars)
  } else {
    feedbackModalOpen.value = true
  }
}
const currentPreviewRating = ref(0)
const previewRating = (stars: number) => {
  currentPreviewRating.value = stars
}
</script>

<style scoped></style>
