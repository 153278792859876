<template lang='pug'>
div
  div(v-if='isLoading')
  div(v-else-if='result'): .card-body
    .d-flex.justify-content-between.align-items-center.mb-3
      h3.h4.font-light
        | {{ $t('js.wizard.components.fairekarriere_widget.bewertungen_von_mitarbeite') }}
      a(:href='organisation.fairekarriere_url' target='_blank')
        img(:src='result.badge_url' style='max-height: 80px')

    .d-flex.flex-column.gap-2
      blockquote.blockquote.w-full(v-for='rating in showRatings')
        strong
          | {{ rating.title  }}
        .d-flex.justify-content-between
          small.text-muted
            | {{ rating.type }}
            |
            | &ndash;
            |
            | {{ rating.standort }}
          stars-badge(:stars='parseFloat(rating.score)')
        div(v-html='rating.comment')
      div(v-if='hasMore')
        button.btn.btn-link(@click.prevent='show += 3') Mehr anzeigen
</template>

<script lang='ts' setup>
import { ref, computed, onMounted } from 'vue'
import * as Routes from "@/routes"
import StarsBadge from '@/components/StarsBadge.vue'

const props = defineProps<{
  organisation: frontend.Organisation & { fairekarriere_url: string },
  }>()

const isLoading = ref(true)
const result = ref<frontend.FairekarriereResult | null>(null)

const show = ref(3)
const showRatings = computed(() => {
  return (result.value && result.value.ratings) ? result.value.ratings.slice(0, show.value) : []
})
const hasMore = computed(() => (result.value && result.value.ratings) && result.value.ratings.length > show.value)

onMounted(() => {
  fetch(Routes.wizardApiFairekarrierePath({ name: props.organisation.username })).then(r => r.json()).then(r => {
    result.value = r
    isLoading.value = false
  })
})

</script>

<style scoped>
</style>
