import Automount from "~/utils/automount"
import EbLivestream from "./EbLivestream.vue"
import EmpfehlungsgraphWrapper from "./EmpfehlungsgraphWrapper.vue"
import EventCountdown from "./EventCountdown.vue"
import BerufeSelector from "./BerufeSelector.vue"
import HistoryEventPage from "./HistoryEventPage.vue"
import ProfileFormStepper from "@/talents/creation_form/ProfileFormStepper.vue"
import Profile from "@/talents/profile/Profile.vue"
import ContactCard from "./ContactCard.vue"
import PricesApp from "./PricesApp.vue"
import WizardCompanyList from "@/wizard/OrganisationList.vue"
import WizardLocation from "@/wizard/Location.vue"
import WizardPortalType from "@/wizard/PortalType.vue"
import ApplyCard from "@/components/apply/ApplyCard.vue"
import PartnerReview from "./PartnerReview.vue"
import GoogleTagManagerConsent from "./GoogleTagManagerConsent.vue"
import LoginEmail from "@/components/LoginEmail.vue"
import JobApplicationPreview  from "@/talents/profile/components/JobApplicationPreview.vue"
import VueTippy from "vue-tippy"
import "tippy.js/dist/tippy.css"
import "tippy.js/themes/material.css"
import "tippy.js/animations/shift-away.css"
import i18n from "@/utils/i18n"
import Toast from "vue-toastification"
import 'vue-toastification/dist/index.css'
import FairekarriereWidget from "@/wizard/components/FairekarriereWidget.vue"
import RejectSurvey from "@/talents/profile/components/RejectSurvey.vue"

import NonGermanIpInterstitial from "@/components/NonGermanIpInterstitial.vue"

const toast = [
  Toast,
  {
    transition: "Vue-Toastification__fade",
    newestOnTop: true
  }
]

const tippy = [
  VueTippy,
  {
    directive: "tooltip",
    component: "tippy",
    componentSingleton: "tippy-singleton",
    defaultProps: {
      theme: "material",
      animation: "shift-away",
      placement: "auto-end",
      allowHTML: true,
    },
  },
]


Automount({
  "eb-livestream": {
    component: EbLivestream,
    plugins: [i18n],
  },
  "eb-graph": {
    component: EmpfehlungsgraphWrapper,
    plugins: [i18n],
  },
  "event-countdown": {
    component: EventCountdown,
    plugins: [i18n],
  },
  "google-tag-manager-consent": {
    component: GoogleTagManagerConsent,
    plugins: [i18n, tippy],
  },
  "berufe-selector": {
    component: BerufeSelector,
    plugins: [i18n],
  },
  "contact-card": {
    component: ContactCard,
    plugins: [i18n],
  },
  "prices-app": {
    component: PricesApp,
    plugins: [i18n, tippy],
  },
  "wizard-organisation-list": {
    component: WizardCompanyList,
    plugins: [i18n, tippy],
  },
  "wizard-location": {
    component: WizardLocation,
    plugins: [i18n, tippy],
  },
  "wizard-portal-type": {
    component: WizardPortalType,
    plugins: [i18n, tippy],
  },
  "apply-card": {
    component: ApplyCard,
    plugins: [i18n, tippy],
  },
  "history-event-page": {
    component: HistoryEventPage,
    plugins: [i18n],
  },
  "partner-review": {
    component: PartnerReview,
    plugins: [i18n],
  },
  "profile-form-stepper": {
    component: ProfileFormStepper,
    plugins: [i18n],
  },
  "profile": {
    component: Profile,
    plugins: [i18n, toast],
  },
  "login-email": {
    component: LoginEmail,
    plugins: [i18n],
  },
  "job-application-preview": {
    component: JobApplicationPreview,
    plugins: [i18n],
  },
  "reject-survey": {
    component: RejectSurvey,
    plugins: [i18n],
  },
  "fairekarriere-widget": {
    component: FairekarriereWidget,
    plugins: [i18n],
  },
  "non-german-ip-interstitial": {
    component: NonGermanIpInterstitial,
    plugins: [i18n],
  },
})
