<template>
  <span class="badge bg-light text-dark m-1" style="height: 22px;" v-for="badge in props.job.badges">
    <span>
      <img v-if="badge.icon != ''" :src="badge.icon" class="mr-1" width="15">
      {{ badge.title }}
    </span>
  </span>
</template>

<script lang="ts" setup>
import EnglishFlag from 'asset_images/english_flag.svg'

import { computed } from "vue"
import { mdiCashMultiple, mdiHomeCityOutline, mdiMapMarkerQuestionOutline, mdiSchool } from "@mdi/js"
import Icon from "@/elements/Icon"
import { useI18n } from "vue-i18n"

const { t } = useI18n()

const props = defineProps<{ job: frontend.Job }>()

const intlHour = new Intl.NumberFormat(window.Locale, { style: "currency", currency: "EUR" })
const intlOther = new Intl.NumberFormat(window.Locale, { style: "currency", currency: "EUR", maximumFractionDigits: 0 })

const isEnglish = computed(() => props.job.language == 'english')

const salary = computed(() => {
  const { salary_min, salary_max, salary_interval } = props.job
  if (!(salary_min && salary_max)) return null

  // i18n-use t("js.wizard.salary_interval.#{salary_interval}") -->
  const interval = t(`js.wizard.salary_interval.${salary_interval}`)

  let intl
  if (salary_interval == "hourly") {
    intl = intlHour
  } else {
    intl = intlOther
  }

  if (salary_min == salary_max) {
    return intl.format(salary_min) + " " + interval
  } else if (salary_min && salary_max) {
    return `${intl.format(salary_min)} - ${intl.format(salary_max)}` + " " + interval
  } else if (salary_min) {
    return `${intl.format(salary_min)}` + " " + interval
  } else if (salary_max) {
    return `${intl.format(salary_max)}` + " " + interval
  }
})
</script>

<style scoped>

</style>
