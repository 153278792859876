<template>
  <div>
    <!-- gerade abgesendete Bewerbung -->
    <div v-if="showApplicationSucceedNote" class="mb-4">
      <ApplySucceed :application="previousApplication" :isExternal="isExternal" :title="$t('js.components.apply_card.succeed')" :job="job"/>
    </div>
    <!-- vorherige Bewerbung -->
    <div v-else-if="previousApplication" class="mb-4">
      <ApplySucceed
        :application="previousApplication"
        :isExternal="isExternal"
        :job="job"
        :title="$t('js.components.apply_card.previous_applied', { date: formatDateTime(previousApplication.applied_on) })" />
    </div>

    <Alert
      v-else-if="applicationInfo && !applicationInfo.is_application_allowed"
      variant="danger"
      :title="$t('js.components.apply.apply_form.closed_title')">
      <p>
        {{ $t("js.components.apply.apply_form.closed_text") }}
      </p>
    </Alert>
    <Alert v-else-if="applicationInfo && applicationInfo.link" variant="success">
      {{ $t("js.components.apply.apply_form.link_title") }}
      <br />
      <br />
      <a class="btn btn-outline-dark btn-lg" :href="applicationInfo.link" target="_blank">
        {{ $t("js.components.apply.apply_form.link_button") }}
      </a>
    </Alert>

    <form v-else-if="applicationInfo && applicationInfo.is_application_allowed" class="max-w-md">
      <PrefilledNote v-if="prefilledFromStorage" @close='prefilledFromStorage = false' @reset='resetForm' :form='form'/>
      <div v-if="!prefilledFromStorage">
        <div class="d-flex gap-2 w-full">
          <FormGroup v-if='fieldVisible("gender")' for='gender' :label='true'>
            <RadioGroup name="gender" v-model="form.gender" :options="genderOptions" />
          </FormGroup>
          <FormInput class="flex-1" name="first_name" v-model="form.first_name" autocomplete="given-name" v-if='fieldVisible("first_name")'/>
          <div class="flex-1" v-if='fieldVisible("last_name")'>
            <FormInput name="last_name" v-model="form.last_name" autocomplete="family-name" />
          </div>
        </div>
        <div class="mt-3">
          <FormInput type="email" name="email" v-model="form.email" autocomplete="email" inputmode="email" />
        </div>
        <template v-if="!isReducedForm">
          <div class="mt-3" v-if='fieldVisible("telephone")'>
            <FormInput name="telephone" v-model="form.telephone" autocomplete="tel" inputmode="tel" />
          </div>

          <AddressFields class='mt-3' v-if='fieldVisible("postal_address")' v-model='form' :applicationInfo='applicationInfo' />

          <div class="mt-4" v-if='fieldVisible("comment")'>
            <FormInput name="comment" type="textarea" rows="3" v-model.lazy="form.comment" />
          </div>

          <FormGroup :label="false" for="files" v-if='fieldVisible("files")'>
            <div v-if='requiredDocuments' class='mt-5 fs-4'>
              <small class='text-muted d-block fs-6'>{{ $t("js.components.apply_card.required_documents") }}</small>
              {{ requiredDocuments }}
            </div>

            <UploadField class="mt-4" :max-size="10 * 1024 * 1024" @uploaded="addUpload" />

            <UploadedFileListItem v-for="file in form.files" :key="file.signed_id" :file="file" @remove="removeUpload" />
          </FormGroup>
        </template>
      </div>
      <template v-if="!isReducedForm">
        <div v-if="applicationInfo.questions.length > 0" class="mt-4">
          <p class="font-semibold">
            {{ $t("js.components.apply_card.answer_those_questions", { company: job.company_name }) }}
          </p>
          <Question
            v-for="q in applicationInfo.questions"
            :name="'question_answers[' + q.question_id + ']'"
            :key="q.question_id"
            v-model="form.question_answers[q.question_id]"
            :question="q"
            class="mb-3" />
        </div>
      </template>

      <transition name="fade">
        <div v-if="!prefilledFromStorage">
          <hr class="my-4" />
          <EmpfehlungscodesField v-model="form.empfehlungscodes" />
        </div>
      </transition>

      <transition name="fade">
        <Alert variant="danger" v-if="error" class="mt-2">
          <div>{{ error }}</div>
        </Alert>
      </transition>
      <Alert variant="info" class="my-2" v-if="isExternal">
        <div style="hyphens: auto" v-if="isReducedForm">
          {{ $t("js.components.apply_card.external_hint_legacy", { company: job.company_name }) }}
        </div>
        <div style="hyphens: auto" v-else>
          {{ $t("js.components.apply_card.external_hint_hybrid", { company: job.company_name }) }}
        </div>
      </Alert>
      <p
        v-if="applicationInfo.privacy_policy_url"
        v-html="
          $t('js.components.apply_card.bms_privacy_hint', { organisation: job.company_name, privacyPolicyUrl: applicationInfo.privacy_policy_url })
        "></p>
      <p v-html="$t('js.components.apply_card.privacy_hint')"></p>
      <Button
        v-show="!isValid"
        variant="primary"
        type="submit"
        class="my-2 opacity-50"
        size="lg"
        @click.prevent="v$.$touch()"
        name="invalidButton"
        v-tooltip="errorTooltip">
        {{ $t("js.components.apply_card.submit") }}
      </Button>
      <Button v-show="isValid" @click.prevent="submit" variant="primary" class="my-2" size="lg" :disabled="isSubmitting">
        {{ $t("js.components.apply_card.submit") }}
      </Button>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, computed, provide, inject, watch, nextTick } from "vue"
import { Button, RadioGroup, Alert, FormGroup, FormInput, UploadField, UploadedFileListItem } from "@/elements"
import ApplySucceed from "./ApplySucceed.vue"
import PrefilledNote from "./PrefilledNote.vue"
import EmpfehlungscodesField from "@/components/apply/EmpfehlungscodesField.vue"
import Question from "@/components/apply/Question"
import AddressFields from "./AddressFields.vue"
import useApply from "@/components/apply/useApply"
import useError from "@/utils/unwrapError"
import csrfToken from "@/utils/csrfToken"
import { useI18n } from "vue-i18n"
import { previousApplicationTo, addApplicationToHistory, applicationCount } from "@/components/apply/history"
import * as Routes from "~/routes"
import { useWindowFocus } from '@vueuse/core'

const { t: $t, locale } = useI18n()

const props = defineProps<{
  job: JobPayload | (frontend.Job & { company_name: string })
}>()

const applicationInfo = ref<ApplicationInfo | null>(null)

const isExternal = computed(() => {
  return applicationInfo.value?.is_external
})
const isReducedForm = computed(() => {
  const v = applicationInfo.value
  return v && v.is_external && !v.required_fields.includes("files")
})

const { form, v$, resetForm, submitPayload } = useApply(applicationInfo)
provide("v", v$)

const initialRecommendations = inject<ValidationSucceed[]>("recommendations")
onMounted(() => {
  if (initialRecommendations && initialRecommendations.length > 0 && (!form.value.empfehlungscodes || form.value.empfehlungscodes.length == 0)) {
    form.value.empfehlungscodes = initialRecommendations
  }
})

const addUpload = (payload: { file: File; blob: ActiveStorage.Blob }) => {
  form.value.files = [...form.value.files, payload.blob]
}
const removeUpload = (blob: ActiveStorage.Blob) => {
  form.value.files = form.value.files.filter((file) => file.signed_id !== blob.signed_id)
}

const errorTooltip = computed(() => {
  return {
    content: `
      <div>
      ${$t("js.components.apply_card.error_tooltip")}
      <ul class="mt-3 list-disc list-outside ml-5">
        ${v$.value.$silentErrors
          .map((e) => e.$message)
          .map((e) => `<li>${e}</li>`)
          .join("")}
      </ul>
      </div>
    `,
    class: "rounded shadow-lg px-4 py-2 bg-warning alert alert-warning",
    tippy: {
      trigger: "hover",
    },
  }
})

const showApplicationSucceedNote = ref(false)

const formatDateTime = (date: string) => {
  const intl = new Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
  })
  return intl.format(new Date(date))
}

const previousApplication = computed(() => {
  return previousApplicationTo(props.job.id)
})

const { error, fromFetchCatch, reset: resetError, isSubmitting } = useError()

const emit = defineEmits(["success"])

const submit = async () => {
  if (v$.value.$invalid) {
    return
  }
  const value = form.value
  resetError()
  const response = await fetch(Routes.jobApplicationCreatePath(props.job.id), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken.value || "",
    },
    body: JSON.stringify(submitPayload.value),
  })
  if (!response.ok) {
    fromFetchCatch(response)
    return
  }
  const json = await response.json()
  isSubmitting.value = false
  showApplicationSucceedNote.value = true
  form.value.question_answers = {}
  addApplicationToHistory(props.job.id, json, value.empfehlungscodes.length > 0)

  emit("success", { json })
  if (applicationCount.value > 10) {
    resetForm()
  }
}
const isValid = computed(() => {
  return !v$.value.$invalid
})

const focused = useWindowFocus()
watch(focused, (isFocused) => {
  if (isFocused) {
    checkPrefilled()
  }
})

onMounted(async () => {
  const response = await fetch(Routes.jobApplicationInfoPath(props.job.id))
  const json = await response.json()

  applicationInfo.value = json
  nextTick(() => {
    checkPrefilled()
  })
})
const prefilledFromStorage = ref(false)

const fieldVisible = (f: string) => {
  if (applicationInfo.value?.visible_fields) {
    return applicationInfo.value.visible_fields.includes(f)
  } else {
    return true
  }
}

const requiredDocuments = computed(() => {
const currentL = locale.value as 'de' | 'en'
  const req = applicationInfo.value?.required_documents
  if (req && req[currentL]) {
    return req[currentL]
  }
})

const genderOptions = computed(() => {
  return [
    { value: 'female', text: $t("js.components.apply.apply_form.gender_options.female") },
    { value: 'male', text: $t("js.components.apply.apply_form.gender_options.male") },
    { value: 'diverse', text: $t("js.components.apply.apply_form.gender_options.diverse") },
  ]
})

import attachmentStillExists from '@/components/apply/attachmentStillExists'

const sessionIdentifier = computed(() => {
  return props.job.id
})

const checkPrefilled = () => {
  if (form.value.session_identifier && sessionIdentifier.value !== form.value.session_identifier) {
    resetForm()
    form.value.session_identifier = sessionIdentifier.value
    return
  }
  if (isValid.value) {
    form.value.files.forEach((file) => {
      attachmentStillExists(file).then(result => {
        if (!result) {
          form.value.files = form.value.files.filter((f) => f.signed_id != file.signed_id)
          prefilledFromStorage.value = prefilledFromStorage.value && form.value.files.length > 0
        }
      })
    })
    prefilledFromStorage.value = true
  } else {
    prefilledFromStorage.value = false
  }
  form.value.session_identifier = sessionIdentifier.value
}
</script>

<style scoped>
.max-w-md {
  max-width: 800px;
}
</style>
